import React from 'react';

const Title = props => {
    const { title } = props;

    return (
        <div className={'h1 component__title'}>
            { title }
        </div>
    );

}

export default Title;