import React from 'react';

const BagesItem = (props) => {
    const { title, number } = props;

    return (
        <>
            <div className={'badge'}>{'\n'}
                <span>{ title }</span>{'\n'}
                <span>{ number }</span>{'\n'}
            </div>{'\n'}
        </>
    )
}

export default BagesItem;