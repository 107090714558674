import React from 'react';
import TextPhotoItem from "./TextPhotoItem";

const TextPhoto = (props) => {
    const { data } = props;

    return (
        <div className={'text-pic'}>{'\n'}
            { data && data.map(item => (
                <>
                    <div className={'text-pic__row'}>{'\n'}
                        <div className={'fix-container'}>{'\n'}
                            <TextPhotoItem
                                key={item.id}
                                reverse={item.reverse}
                                title={item.title}
                                text={item.text}
                                image_url={item.image_url}
                                image_alt={item.image_alt}
                                rightClass={item.right_class}
                                leftClass={item.left_class}
                            />{'\n'}
                        </div>{'\n'}
                    </div>{'\n'}
                </>
            ))}
        </div>
    )
}

export default TextPhoto;