import React from 'react';
import FeatureItem from "./FeatureItem";

const Feature = props => {
    const { data, title } = props;

    return (
        <div className={'features'}>{'\n'}
            <div className={'features__title'}>{'\n'}
                <div className={'fix-container'}>{'\n'}
                    <div className={'h2'}>{ title }</div>{'\n'}
                </div>{'\n'}
            </div>{'\n'}
            <div className={'fix-container'}>{'\n'}
                <div className={'features__items'}>{'\n'}
                    { data && data.map(item => (
                        <>
                            <FeatureItem
                                key={item.id}
                                title={item.title}
                                text={item.text}
                                icon={item.icon}
                                icon_alt={item.icon_alt}
                            />{'\n'}
                        </>
                    ))}
                </div>{'\n'}
            </div>{'\n'}
        </div>
    )
}

export default Feature;