import React from 'react';

const Picture = props => {
    const { url, alt_text, additional_class } = props;

    return (
        <picture className={`${additional_class ? additional_class : 'bordered'}`}>{'\n'}
            <source srcSet={url} />{'\n'}
            <img src={url} alt={alt_text} />{'\n'}
        </picture>
    )
}

export default Picture;