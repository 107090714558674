import React from 'react';

const SmallCard = (props) => {
    const { title, text, number, additionalClass } = props;

    return (
        <>
            <div className={`small-card  ${additionalClass ? additionalClass : ''}`}>{'\n'}
                <div className={'small-card__title'}>{'\n'}
                    { title }{'\n'}
                </div>{'\n'}

                {text &&
                    <>
                        <div className={'small-card__text'}>{'\n'}
                            { text }{'\n'}
                        </div>{'\n'}
                    </>
                }

                <div className={'small-card__number'}>{'\n'}v
                    { number }{'\n'}
                </div>{'\n'}
            </div>{'\n'}
        </>
    )
}

export default SmallCard;