import React from 'react';
import BagesItem from "./BagesItem";

const BagesList = (props) => {
    const { bages } = props;

    return (
        <div className={'bages-list'}>{'\n'}
            <div className={'fix-container'}>{'\n'}
                <div className={'h2'}>{'\n'}
                    Компоненты и дозировки{'\n'}
                </div>{'\n'}
                <div className={'bages-list__wrapper'}>{'\n'}
                    { bages.map((badge) => (
                        <BagesItem
                            key={badge.id}
                            title={badge.title}
                            number={badge.number}
                        />
                    ))}
                </div>{'\n'}
            </div>{'\n'}
        </div>
    )
}

export default BagesList;