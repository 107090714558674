import React from 'react';
import Grid2Columns from "../UI/Block/2columns";
import Picture from "../UI/Picture/Picture";

const TextPhotoItem = (props) => {
    const { title, text, image_url, image_alt, reverse, leftClass, rightClass } = props;

    return (
        <Grid2Columns
            reverse={reverse}
            left={
                <>
                    <div className={'h2'}>{ title }</div>{'\n'}
                    <p>{ text }</p>
                </>
            }
            right={
                <Picture
                    url={image_url}
                    alt_text={image_alt}
                />
            }
            rightClass={rightClass ? rightClass : ''}
            leftClass={leftClass ? leftClass : ''}
        />
    )
}

export default TextPhotoItem;