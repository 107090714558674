import React from 'react';

const Grid2Columns = props => {
    const { reverse, leftClass, rightClass } = props;
    return (
        <>
            <div className={`columns2 ${ reverse ? 'columns2--reverse' : ''} `}>{'\n'}
                <div className={`columns2__block ${leftClass ? leftClass : '' }`}>{'\n'}
                    { props.left }{'\n'}
                </div>{'\n'}
                <div className={`columns2__block ${rightClass ? rightClass : ''}`}>{'\n'}
                    { props.right }{'\n'}
                </div>{'\n'}
            </div>
        </>
    )
}

export default Grid2Columns;