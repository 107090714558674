import React from 'react';
import Title from './Title';

const Block = props => {
    const { title, id } = props;
    return (
        <div id={id} className={'component__block'}>
            <Title title={title} />
            <div className={'component__item'}>
                { props.children }
            </div>
        </div>
    )
}

export default Block;