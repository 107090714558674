import React from 'react';
import Grid2Columns from "../UI/Block/2columns";

const TextBlock = (props) => {
    const { title, subtitle, text, reverse } = props;

    return (
        <div className={'text-block'}>{'\n'}
            <div className={'fix-container'}>{'\n'}
                <Grid2Columns
                    left={
                        <div className={`text-block__title ${ reverse ? 'text-block__title--reverse' : '' }`}>{'\n'}
                            <div className={'h2'}>{ title }</div>{'\n'}
                            <div className={'h6'}>{ subtitle }</div>{'\n'}
                        </div>
                    }
                    right={
                        <div className={'text-block__description'}>{'\n'}
                            <p>{ text }</p>{'\n'}
                        </div>
                    }
                />{'\n'}
            </div>{'\n'}
        </div>
    )
}

export default TextBlock;