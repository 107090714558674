import React from 'react';
import Picture from '../UI/Picture/Picture';

const PictureBlock = () => {
    return (
        <div className={'picture-block'}>{'\n'}
            <div className={'fix-container'}>{'\n'}
                <Picture
                    url={'/assets/img/picture/image.png'}
                    alt_text={'Просто картинка'}
                />{'\n'}
            </div>{'\n'}
        </div>
    )
}

export default PictureBlock;