import React from 'react';
import components from '../../../data/components.json';

const Navigation = () => {
    return (
        <div className={'app__navigation'}>
            <div className={'h3'}>Содержание</div>
            <ul>
                { components && components.map(component => (
                    <li key={component.id}>
                        <a href={`#${component.target}`}>
                            {component.id}. {component.name}
                        </a>
                    </li>
                ))}
            </ul>
        </div>
    )
}

export default Navigation;