import React from 'react';
import SmallCard from "./SmallCard";

const SmallCardsGrid = (props) => {
    const { cards } = props;

    return (
        <div className={'small-cards'}>{'\n'}
            <div className={'fix-container'}>{'\n'}
                <div className={'h2'}>{'\n'}
                    Пищевая ценность продукта{'\n'}
                </div>{'\n'}
                <div className={'small-cards__wrapper'}>{'\n'}
                    <div className={'small-cards__row small-cards__col4'}>{'\n'}
                        { cards.map((card, index) => {
                            return index < 4 ?
                                <SmallCard
                                    key={card.id}
                                    title={card.title}
                                    text={card.text}
                                    number={card.number}
                                    additionalClass={card.additionalClass}
                                /> :
                                null;
                        })}
                    </div>{'\n'}
                    <div className={'small-cards__row small-cards__col3'}>{'\n'}
                        { cards.map((card, index) => {
                            return index > 3 && index < 7 ?
                                <SmallCard
                                    key={card.id}
                                    title={card.title}
                                    text={card.text}
                                    number={card.number}
                                    additionalClass={card.additionalClass}
                                />
                                :
                                null;
                        })}
                    </div>{'\n'}
                    <div className={'small-cards__row small-cards__col4'}>{'\n'}
                        { cards.map((card, index) => {
                            return index > 6 && index < 11 ?
                                <SmallCard
                                    key={card.id}
                                    title={card.title}
                                    text={card.text}
                                    number={card.number}
                                    additionalClass={card.additionalClass}
                                />
                                :
                                null;
                        })}
                    </div>{'\n'}
                </div>{'\n'}
            </div>{'\n'}
        </div>
    )
}

export default SmallCardsGrid;