import React from 'react';
import { renderToStaticMarkup } from 'react-dom/server';
import CodeCopy from 'react-codecopy'


const CodeExample = props => {
   return (
       <CodeCopy text={renderToStaticMarkup(props.children)}>
           <pre className={'component__html'}>
            <code>
                { renderToStaticMarkup(props.children) }
            </code>
        </pre>
       </CodeCopy>
   );
}

export default CodeExample;
