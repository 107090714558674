import React from 'react';
import Text from './Component/Text'
import CodeExample from './Component/CodeExample';
import Block from './Component/Block';

const Component = props => {
    const { text, title, id } = props;

    return (
        <Block title={title} id={id}>
            <div className={'component__inner'}>
                { props.children }
            </div>

            <div className={"component__description"}>
                <div className={"container"}>
                    <Text>
                        { text }
                    </Text>
                    <CodeExample>
                        { props.children }
                    </CodeExample>
                </div>
            </div>
        </Block>
    )
}

export default Component;