import React from 'react';
import Picture from "../UI/Picture/Picture";

const FeatureItem = props => {
    const { icon, icon_alt, title, text } = props;

    return (
        <div className={"feature-item"}>{'\n'}
            <Picture
                additional_class={"feature-item__icon"}
                url={icon}
                alt_text={icon_alt}
            />{'\n'}
            <div className={"feature-item__description"}>{'\n'}
                <div className={'h6'}>{ title }</div>{'\n'}
                <p>{ text }</p>{'\n'}
            </div>{'\n'}
        </div>
    )
}

export default FeatureItem;