import React from 'react';

const TextBordered = (props) => {
    return (
        <div className={'text-bordered'}>{'\n'}
            <div className={'fix-container'}>{'\n'}
                <p>{'\n'}
                    { props.children }{'\n'}
                </p>{'\n'}
            </div>{'\n'}
        </div>
    )
}

export default TextBordered;