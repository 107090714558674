import React from 'react';

const TextList = (props) => {
    const { data } = props;

    return (
        <div className={'text-list'}>{'\n'}
            <ul className={'fix-container'}>{'\n'}
                { data && data.map(item => (
                    <>
                        <li>
                            <div className={'h4'}>{item.list_text}</div>
                        </li>{'\n'}
                    </>
                )) }
            </ul>{'\n'}
        </div>
    )
}

export default TextList;