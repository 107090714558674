import React from 'react';
import Card from "./Card";

const GridCard = (props) => {
    const { cards } = props;
    return (
        <>
            <div className={"info-grid"}>{'\n'}
                <div className={'fix-container'}>{'\n'}
                    {cards && cards.map(card => (
                        <>
                            <Card key={card.id} card={card} />{'\n'}
                        </>
                    ))}
                </div>{'\n'}
            </div>{'\n'}
        </>
    )
}

export default GridCard;