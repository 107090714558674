import React from 'react';
import Grid2Columns from "../UI/Block/2columns";
import Picture from "../UI/Picture/Picture";

const Accent = () => {
    return (
        <div className={'accent'}>{'\n'}
            <div className={'fix-container'}>{'\n'}
                <Grid2Columns
                    left={
                        <Picture
                            url={'/assets/img/accent/image.png'}
                            alt_text={'iPhone 13 Pro'}
                        />
                    }
                    right={
                        <div className={'accent__description'}>{'\n'}
                            <div className={'h2'}>iPhone 13 Pro</div>{'\n'}
                            <div className={'accent__description-text'}>{'\n'}
                                <div className={'accent__description-item'}>{'\n'}
                                    <img src={'/assets/img/accent/icon2.svg'} alt={'Аккумулятор'} />{'\n'}
                                    <div className={'h6'}>работает <span>от аккумулятора</span> дольше любого другого iPhone.</div>{'\n'}
                                </div>{'\n'}
                                <div className={'accent__description-item'}>{'\n'}
                                    <img src={'/assets/img/accent/icon1.svg'} alt={'Зарядка'} />{'\n'}
                                    <div className={'h6'}>А с зарядным устройством <span>MagSafe они</span> будут заряжаться ещё быстрее.</div>{'\n'}
                                </div>{'\n'}
                            </div>{'\n'}
                        </div>
                    }
                />{'\n'}
            </div>{'\n'}
        </div>
    )
}

export default Accent;