import React from 'react';
import TextBlock from "../TextBlock/TextBlock";
import GridCard from "../Cards/GridCard";

const TextCards = (props) => {
    const { title, subtitle, text, cards } = props.data;

    return (
        <div className={'text-cards'}>{'\n'}
            <TextBlock
                title={title}
                subtitle={subtitle}
                text={text}
                reverse
            />{'\n'}

            <GridCard cards={cards} />{'\n'}
        </div>
    )
}

export default TextCards;