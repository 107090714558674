import React from 'react';
import Picture from "../UI/Picture/Picture";

const Card = (props) => {
    const { card } = props;

    return(
        <div className={'info-grid__card'}>{'\n'}
            <div className={`info-grid__card-inner ${ card.additional_class ? 'info-grid__card-inner--' + card.additional_class : ''}` }>{'\n'}
                <Picture
                    additional_class={'info-grid__card-inner-image'}
                    url={card.image_url}
                    alt_text={card.image_alt}
                />{'\n'}
                <div className={"info-grid__card-inner-text"}>{'\n'}
                    <div className={'h5'}>{ card.title }</div>{'\n'}
                    <p>{ card.text }</p>{'\n'}
                </div>{'\n'}
            </div>{'\n'}
        </div>
    )
}

export default Card;