import React from 'react';

const TextBackground = (props) => {
    return (
        <div className={'text-background'}>{'\n'}
            <div className={'fix-container'}>{'\n'}
                <div className={'text-background__bg'}>{'\n'}
                    { props.children }
                </div>{'\n'}
            </div>{'\n'}
        </div>
    )
}

export default TextBackground;