import React from 'react';

const Text = props => {
    return (
        <div className={'component__text'}>
            <p>
                { props.children }
            </p>
        </div>
    )
}

export default Text;